.height-fill {
    height: 100vh !important;
}

/* LoadingDots.css */
.loading-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: red;
    border-radius: 50%;
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}

/* .slick-list, .slick-track, .slick-slide{
    height: 100% !important;
} */